


















































































































































































import { type PropType, defineComponent } from "@vue/composition-api";
import {
	mapActions as mapPiniaActions,
	mapWritableState as mapPiniaWritableState,
	mapState,
} from "pinia";
import { mapActions } from "vuex";
import { getRelativeOccupancy } from "@/lib/getRelativeOccupancy";
import { logger } from "@/logger";
import { useAppStore } from "@/pinia/app";
import { useLocationStore } from "@/pinia/location";
import { useMemberStore } from "@/pinia/member";
import { getDateFromDatum, getDayMonthYear } from "@/utils/date";

type Data = {
	herhalend: boolean;
	message: string;
	error: boolean;
	lidId?: number;
};

export default defineComponent({
	props: {
		data: {
			type: Object as PropType<{
				search: string;
				les: {
					les_id: number;
					groepsles_naam: string;
					datum: string;
					bezet: number;
					plekken: number;
					tijd: string;
					vestiging_naam: string;
				};
				lid: { id: number; lidId: number };
			}>,
			default: () => ({}),
		},
		callback: {
			type: Function as PropType<() => void | Promise<void>>,
			default: () => {
				return;
			},
		},
	},
	data(): Data {
		return {
			herhalend: false,
			message: "",
			error: false,
			lidId: undefined,
		};
	},
	computed: {
		...mapState(useMemberStore, {
			members: "members",
			membersLoading: ({ state: { loading } }) => loading,
		}),
		...mapState(useLocationStore, {
			location: "location",
			locations: "locationsSortedByName",
		}),
		...mapPiniaWritableState(useMemberStore, {
			filters: "filters",
		}),
		...mapState(useAppStore, { occupancyFormat: "occupancyFormat" }),
	},
	watch: {
		filters: {
			async handler() {
				await this.emptyMembers();
				await this.resetMembersState();
				await this.getMembersDebounced();
			},
			deep: true,
		},
	},
	async created() {
		await this.initMembers();
		await this.initLocation();

		await this.resetMembersState();
		await this.setFilters({
			search: "",
			vestiging: this.location?.slug ?? "all",
		});
		await this.getMembers();
	},
	mounted() {
		if (this.data && this.data.lid && this.data.lid.lidId) {
			this.lidId = this.data.lid.lidId;
		} else if (this.data && this.data.lid && this.data.lid.id) {
			this.lidId = this.data.lid.id;
		}
	},
	methods: {
		...mapPiniaActions(useMemberStore, {
			initMembers: "init",
			getMembers: "get",
			emptyMembers: "empty",
			getMembersDebounced: "debouncedGet",
			setFilters: "setFilters",
			resetFilters: "resetFilters",
			setMembersState: "setState",
			resetMembersState: "resetState",
		}),
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapActions("tijdsloten", {
			postAanwezigheidsLijst: "postAanwezigheidsLijst",
		}),
		...mapActions("modal", { openModal: "openModal" }),
		aanmelden() {
			this.$validator
				.validateAll()
				.then((valid) => {
					if (valid) {
						const query = this.data.search
							? {
									lesId: +this.data.les.les_id,
									lijst: [
										{
											lidId: this.lidId,
											herhalend: false,
											status: "aangemeld",
										},
									],
								}
							: {
									lesId: +this.data.les.les_id,
									lijst: [
										{
											lidId: +this.data.lid.lidId,
											herhalend: this.herhalend,
											status: "aangemeld",
										},
									],
								};
						this.postAanwezigheidsLijst(query)
							.then(() => {
								this.message = "Je hebt het lid succesvol aangemeld.";
								this.callback();
							})
							.catch((err) => {
								this.error = true;
								if (err instanceof Error) {
									this.message = err.message;
								} else {
									this.message = String(err);
								}
							});
					}
				})
				.catch(logger.error);
		},
		openTempMemberModal() {
			this.openModal({
				name: "temp-member",
				data: {
					popup: {
						enable: false,
					},
				},
				callback: async (closeModal: () => void, user: { id: number }) => {
					closeModal();
					this.openModal({
						name: "groepsles-aanmelden",
						data: {
							...this.data,
							lid: user,
							search: false,
						},
						callback: this.callback
							? this.callback
							: () => {
									return;
								},
					});
				},
			});
		},
		getRelativeOccupancy,
		getDateFromDatum,
		getDayMonthYear,
	},
});
