<template>
	<div>
		<header class="pfg-header">
			<div class="pfg-header_heading">
				<h1 class="s_elem-has-icon-left a_fill_default">
					{{ capitalize(type) }} Annuleren
					<pfg-icon icon-id="icon_groepslessen" />
				</h1>
			</div>

			<div v-if="!message" class="">
				<p>
					Weet je zeker dat je {{ type === "les" ? "de" : "het" }} {{ type }} wilt annuleren? <br />
					Dit kan niet ongedaan worden gemaakt. Vul altijd een reden in.
				</p>
			</div>
		</header>

		<hr class="v_less-margin" />
		<div class="a_padding-20 a_bg_brand_white">
			<div class="pfg-content_flex">
				<div class="pfg-content_1-2">
					<table class="a_no-margin">
						<tr>
							<td style="width: 100px" class="a_text_brand_primary-lighter">Groepsles:</td>
							<td>{{ les.groepsles_naam }}</td>
						</tr>
						<tr>
							<td class="a_text_brand_primary-lighter">Datum:</td>
							<td>{{ les.datum }}</td>
						</tr>
						<tr>
							<td class="a_text_brand_primary-lighter">Tijd:</td>
							<td>{{ les.tijd }}</td>
						</tr>
					</table>
				</div>
				<div class="pfg-content_1-2">
					<table class="a_no-margin">
						<tr>
							<td style="width: 100px" class="a_text_brand_primary-lighter">Zaal:</td>
							<td>{{ les.zaal_naam }}</td>
						</tr>
						<tr>
							<td class="a_text_brand_primary-lighter">Bezetting:</td>
							<td v-if="occupancyFormat === 'relative'">
								{{ getRelativeOccupancy(les.bezet, les.plekken) }}%
							</td>
							<td v-else>{{ les.bezet }} / {{ les.plekken }}</td>
						</tr>
						<tr>
							<td class="a_text_brand_primary-lighter">Trainer:</td>
							<td>{{ les.trainer_naam }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<textarea
			v-show="!message"
			v-model="reden"
			v-validate="'required'"
			name="reden"
			placeholder="Reden tot annuleren"
		></textarea>
		<span v-show="errors.has('reden') && !message">vul een reden in</span>

		<hr v-show="!message" class="v_less-margin" />

		<div v-if="message" :class="{ 'error-message': error }">
			{{ message }}
			<br />
			<br />
		</div>

		<div class="pfg-button_group">
			<button
				class="button v_ghost_brand_secondary v_has-icon-right v_smaller a_margin-left-10"
				@click="$emit('close')"
			>
				<span class="button-txt">{{ message ? "Sluiten" : "Nee, toch niet" }}</span>
			</button>
			<button
				v-if="!message"
				:disabled="!reden"
				class="button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-left-10"
				@click="annuleren"
			>
				<span class="button-txt">Ja, ik weet het zeker</span>
				<pfg-icon icon-id="icon_afmelden" />
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getRelativeOccupancy } from "@/lib/getRelativeOccupancy";
import { capitalize } from "@/utils/capitalize";

export default {
	name: "ModalLesAnnuleren",
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		callback: {
			type: Function,
			default: () => {
				return;
			},
		},
	},
	data() {
		return {
			message: "",
			reden: "",
			error: false,
			type: this.data.type ? this.data.type : "les",
		};
	},
	computed: {
		...mapState("instellingen", ["occupancyFormat"]),
		les() {
			return this.data.les;
		},
	},
	methods: {
		...mapActions("groepsles", ["lesAnnuleren"]),
		annuleren() {
			this.lesAnnuleren({ reden: this.reden, id: +this.data.lesId })
				.then(() => {
					this.message = "De les is succesvol geannuleerd";
					this.callback();
				})
				.catch((err) => {
					err = JSON.parse(err);
					this.error = true;
					if (err && err.error && err.error.message) {
						this.message = err.error.message;
					} else {
						this.message = "er is iets fout gegaan, probeer het later nog eens.";
					}
				});
		},
		getRelativeOccupancy,
		capitalize,
	},
};
</script>

<style scoped lang="scss">
.error-message {
	color: red;
}
</style>
