import { axios } from "@/axios";
import service from "@/lib/service";

/** @deprecated Please use `pinia` instead */
const afspraak = {
	namespaced: true,
	actions: {
		getAppointments(_, { date, location }) {
			return new Promise((resolve, reject) => {
				if (!date) {
					return reject("no date specified");
				}
				if (!location) {
					return reject("no location specified");
				}
				return axios
					.get(`appointments-month/${location}/${date}`)
					.then((response) => {
						if (response.status === 200) {
							resolve(response.data);
						}
					})
					.catch((err) => {
						reject(err.response);
					});
			});
		},
		getBookedAppointmentsRange(_, { data, locationName }) {
			return service.post(`appointments-booked/range/${locationName}`, data);
		},
		cancelAppointment(_, { opmerkingen, afspraakId, reden, ...rest }) {
			const cancellationComment = `Reden van annulering: ${
				reden ? reden.trim() : "geen reden opgegeven"
			}`;
			let composedComment = cancellationComment;
			if (opmerkingen) {
				composedComment = composedComment + "\n\n" + opmerkingen;
			}
			return service.post(`fitnessafspraken/${afspraakId}/aanwezigheid`, {
				...rest,
				opmerkingen: composedComment,
				status: "afgemeld",
			});
		},
	},
};

export default afspraak;
