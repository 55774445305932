import { defineStore } from "pinia";
import { clubApi } from "@/lib/backend";
import type { IomodelsCoachMemberExtern, IomodelsOverviewFilter } from "@/lib/backend/club.api";
import { LS_MEMBERS_FILTERS } from "@/lib/constants/localstorage";
import { debounce } from "@/utils/debounce";

interface MembersState {
	initialized: boolean;
	members: IomodelsCoachMemberExtern[];
	state: {
		loading: boolean;
		dirty?: boolean;
		endReached: boolean;
		page: number;
	};
	filters: Omit<IomodelsOverviewFilter, "page">;
}

const DEBOUNCE_TIME = 600;

export const useMemberStore = defineStore("members", {
	state: (): MembersState => ({
		initialized: false,
		members: [],
		state: {
			loading: false,
			dirty: undefined,
			endReached: false,
			page: 1,
		},
		filters: {
			vestiging: "all",
			sort: "check-in",
			search: "",
			coachId: undefined,
			coachingsGroep: undefined,
		},
	}),
	actions: {
		async init() {
			if (this.initialized) {
				return;
			}

			this.initialized = false;

			await this.getPreference();
		},
		async get() {
			if (this.state.loading || this.state.endReached) {
				return;
			}

			if (this.state.dirty) {
				this.members = [];
			}

			this.state.loading = true;

			const response = await clubApi.leden.overviewVoorCoachApp({
				...this.filters,
				page: this.state.page,
			});

			switch (response.status) {
				case 200: {
					const { users, nextPage } = response.data;

					this.state.page += 1;

					if (this.state.dirty) {
						this.state.dirty = false;
					}

					this.state.endReached = !nextPage;

					users.forEach((user) => {
						if (!this.members.find((member) => member.id === user.id)) {
							this.members.push(user);
						}
					});

					this.state.loading = false;

					return;
				}

				default: {
					this.state.loading = false;

					throw response;
				}
			}
		},
		async debouncedGet() {
			debounce(this.get, DEBOUNCE_TIME)();
		},
		async getPreference() {
			const lsMembersFilters = localStorage.getItem(LS_MEMBERS_FILTERS);

			if (lsMembersFilters) {
				this.filters = {
					...this.filters,
					...(JSON.parse(lsMembersFilters) as Partial<MembersState["filters"]>),
				};
			}
		},
		async setFilters(filtersToSet: Partial<MembersState["filters"]>) {
			this.filters = {
				...this.filters,
				...filtersToSet,
			};
		},
		async resetFilters() {
			this.filters = {
				vestiging: "all",
				sort: "check-in",
				search: "",
				coachId: undefined,
				coachingsGroep: undefined,
			};
		},
		async setState(stateToSet: Partial<MembersState["state"]>) {
			this.state = {
				...this.state,
				...stateToSet,
			};
		},
		async resetState() {
			this.state = {
				loading: false,
				dirty: false,
				endReached: false,
				page: 1,
			};
		},
		async empty() {
			this.members = [];
		},
		async reset() {
			await this.resetFilters();
			await this.resetState();
			await this.empty();
		},
	},
});

export default useMemberStore;
