














































































import { type PropType, defineComponent } from "@vue/composition-api";
import { mapActions as mapPiniaActions, mapState, mapStores } from "pinia";
import { mapActions } from "vuex";
import { clubApi } from "@/lib/backend";
import type {
	IomodelsCoachResult,
	IomodelsCoachappAlgemeneCoachStatistieken,
} from "@/lib/backend/club.api";
import useCoachStore from "@/pinia/coach";
import useLocationStore from "@/pinia/location";

type Data = {
	selected: IomodelsCoachResult | null;
	isOpen: boolean;
	coachesStatistics: Map<number, IomodelsCoachappAlgemeneCoachStatistieken>;
};

export default defineComponent({
	props: {
		data: {
			type: Object,
			required: true,
		},
		callback: {
			type: Function as PropType<() => void | Promise<void>>,
			required: false,
			default: () => false,
		},
	},
	data(): Data {
		return {
			isOpen: false,
			selected: null,
			coachesStatistics: new Map<number, IomodelsCoachappAlgemeneCoachStatistieken>(),
		};
	},
	computed: {
		...mapStores(useLocationStore, useCoachStore),
		...mapState(useCoachStore, ["coachesByCurrentLocationSortedByName"]),
	},
	watch: {
		coachesByCurrentLocationSortedByName: {
			immediate: true,
			async handler(coaches: IomodelsCoachResult[]) {
				const _coachesStatistics = await Promise.all(
					coaches.map(async (coach) => {
						const result = await clubApi.coachDashboard.getAlgemeneStatistieken(coach.id);
						const data = result?.data;
						if (!data) return null;

						return {
							id: coach.id,
							...data,
						};
					}),
				);

				this.coachesStatistics = _coachesStatistics.reduce((acc, cur) => {
					if (cur) {
						acc.set(cur.id, cur);
					}
					return acc;
				}, new Map<number, IomodelsCoachappAlgemeneCoachStatistieken>());
			},
		},
	},
	async created() {
		await this.locationStore.init();
		await this.coachStore.init();
	},
	methods: {
		...mapActions("modal2", {
			// Need to use modal2, because modal is already used in the parent component
			openModal: "openModal",
		}),

		...mapPiniaActions(useCoachStore, {
			getCoaches: "getCoaches",
		}),

		getCoachThirtyPercentSporters(coachId: number) {
			return this.coachesStatistics.get(coachId)?.aantal30ProcentSporters ?? null;
		},

		selectItem(item: IomodelsCoachResult) {
			this.selected = item;
			this.isOpen = false;
		},

		getAmount() {
			return this.getMemberIds().length;
		},

		getMemberIds() {
			return this.data.memberIds;
		},

		close() {
			this.$emit("close");
			this.callback();
		},

		confirmAction2() {
			const _selected = this.selected;
			const _notify = this.$notify;
			const _getMemberIds = this.getMemberIds;
			const closeModal = this.close;

			async function confirmCallback() {
				if (!_selected) return;
				const body = {
					ledenIds: _getMemberIds(),
				};

				try {
					await clubApi.coach.ledenToewijzen(_selected.id, body);

					_notify({
						title: "Leden zijn toegewezen",
						type: "success",
					});
					closeModal();
				} catch (err) {
					_notify({
						title: "Er is iets misgegaan",
						text: (err as unknown as { message: string }).message ?? err,
						type: "error",
					});
				}
			}

			const amount = this.getMemberIds().length;
			this.openModal({
				name: "confirm",
				data: {
					amount: this.getMemberIds().length,
					message: `Weet je zeker dat je ${
						amount < 1 ? amount + " lid" : amount + " leden"
					} wil aannemen?`,
				},
				async callback() {
					confirmCallback();
				},
			});
		},
	},
});
