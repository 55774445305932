import Vue from "vue";
import VueRouter, { type RouteConfig } from "vue-router";
import { z } from "zod";
import { useAppStore } from "@/pinia/app";
import { useAuthStore } from "@/pinia/auth";
import { requireAuth, requireUnauth } from "./authGuard";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
	{
		name: "Home",
		path: "/",
		alias: "/index.html",
		beforeEnter: (__to, __from, next) => {
			const auth = useAuthStore();
			auth.init();

			if (auth.isLoggedIn) {
				return next({ name: "Coach dashboard" });
			} else {
				return next({ name: "Login" });
			}
		},
	},
	{
		name: "Login",
		path: "/login",
		beforeEnter: requireUnauth,
		component: async () => await import(/* webpackChunkName: "views/login" */ "@/views/Login.vue"),
		meta: {
			hideTopbar: true,
			fullScreen: true,
		},
	},
	{
		name: "Live feed",
		path: "/live-feed",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/live-feed" */ "@/views/LiveFeed.vue"),
	},
	{
		name: "Leden",
		path: "/leden",
		beforeEnter: requireAuth,
		component: async () => await import(/* webpackChunkName: "views/leden" */ "@/views/Leden.vue"),
	},
	{
		name: "Coach dashboard",
		path: "/coach-dashboard",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/coach-dashboard" */ "@/views/CoachDashboard.vue"),
	},
	{
		name: "Fitness blokken",
		path: "/fitness-blokken",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/fitness-blokken" */ "@/views/FitnessBlokken.vue"),
	},
	{
		name: "Fitness blok detail",
		path: "/fitness-blokken/:id/detail",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/fitness-blokken/detail" */ "@/views/FitnessBlokDetail.vue"
			),
	},
	{
		name: "Fitness blok detail print",
		path: "/fitness-blokken/:id/detail/print",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/fitness-blokken/detail/print" */ "@/views/FitnessBlokDetailPrint.vue"
			),
		meta: { hideTopbar: true },
	},
	{
		name: "Groepslessen",
		path: "/groepslessen",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/groepslessen" */ "@/views/Groepslessen.vue"),
	},
	{
		name: "Groepslessen rooster",
		path: "/groepslessen/rooster",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/groepslessen/rooster" */ "@/views/GroepslessenRooster.vue"
			),
	},
	{
		name: "Groepsles detail",
		path: "/groepslessen/:id/detail",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/groepslessen/detail" */ "@/views/GroepslesDetail.vue"
			),
	},
	{
		name: "Groepsles detail print",
		path: "/groepslessen/:id/detail/print",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/groepslessen/detail/print" */ "@/views/GroepslesDetailPrint.vue"
			),
		meta: { hideTopbar: true },
	},
	{
		name: "Afspraken",
		path: "/afspraken",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/afspraken" */ "@/views/Afspraken.vue"),
	},
	{
		name: "Formulieren",
		path: "/formulieren",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/formulieren" */ "@/views/Formulieren.vue"),
	},
	{
		name: "Instellingen",
		path: "/instellingen",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/instellingen" */ "@/views/Instellingen.vue"),
	},
	{
		path: "/gebruiker/:id",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/gebruiker" */ "@/views/gebruiker/Gebruiker.vue"),
		meta: { gebruikerView: true },
		children: [
			{
				name: "Gebruiker",
				path: "",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/dashboard" */ "@/views/gebruiker/Dashboard.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker persoonlijk gegevens",
				path: "persoonlijk/gegevens",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/persoonlijk/gegevens" */ "@/views/gebruiker/PersoonlijkGegevens.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker persoonlijk medisch",
				path: "persoonlijk/medisch",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/persoonlijk/medisch" */ "@/views/gebruiker/PersoonlijkMedisch.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker persoonlijk abonnementsvorm",
				path: "persoonlijk/abonnementsvorm",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/persoonlijk/abonnementsvorm" */ "@/views/gebruiker/PersoonlijkAbonnementsvorm.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker profielafbeelding",
				path: "profiel-afbeelding",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/profiel-afbeelding" */ "@/views/gebruiker/ProfielAfbeelding.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				path: "metingen",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/metingen" */ "@/views/gebruiker/metingen/Parent.vue"
					),
				children: [
					{
						name: "Gebruiker metingen",
						path: "",
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/metingen" */ "@/views/gebruiker/metingen/Metingen.vue"
							),
						meta: { gebruikerView: true },
					},
					{
						name: "Gebruiker meting",
						path: "meting/:metingId?",
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/metingen/meting" */ "@/views/gebruiker/metingen/Meting.vue"
							),
						meta: { hideTopbar: true, gebruikerView: false },
					},
				],
			},
			{
				name: "Gebruiker doel",
				path: "doel",
				component: async () =>
					await import(/* webpackChunkName: "views/gebruiker/doel" */ "@/views/gebruiker/Doel.vue"),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker schema",
				path: "schema",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/schema" */ "@/views/gebruiker/Schema.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker groepslessen",
				path: "groepslessen",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/groepslessen" */ "@/views/gebruiker/Groepslessen.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker groepsles detail",
				path: "groepslessen/:groepslesId/detail",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/groepslessen/detail" */ "@/views/gebruiker/GroepslesDetail.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker activiteiten",
				path: "activiteiten",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/activiteiten" */ "@/views/gebruiker/Activiteiten.vue"
					),
				meta: { gebruikerView: true },
			},
			{
				name: "Gebruiker berichten",
				path: "berichten",
				component: async () =>
					await import(
						/* webpackChunkName: "views/gebruiker/berichten" */ "@/views/gebruiker/berichten/index.vue"
					),
				props: (route) => {
					const resultParams = z
						.object({
							memberId: z.coerce.number(),
						})
						.safeParse({
							memberId: route.params.id,
							...route.params,
						});

					if (!resultParams.success) {
						throw new Error("Invalid route params for 'Gebruiker berichten'", {
							cause: resultParams.error,
						});
					}

					return resultParams.data;
				},
				meta: { gebruikerView: true },
				children: [
					{
						name: "Gebruiker nieuw bericht",
						path: "nieuw",
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/berichten/nieuw" */ "@/views/gebruiker/berichten/nieuw.vue"
							),
						props: (route) => {
							const resultParams = z
								.object({
									memberId: z.coerce.number(),
								})
								.safeParse({
									memberId: route.params.id,
									...route.params,
								});

							if (!resultParams.success) {
								throw new Error("Invalid route params for 'Gebruiker nieuw bericht'", {
									cause: resultParams.error,
								});
							}

							return resultParams.data;
						},
						meta: { gebruikerView: true },
					},
					{
						name: "Gebruiker bericht",
						path: "bericht/:messageId",
						component: async () =>
							await import(
								/* webpackChunkName: "views/gebruiker/berichten/messageId" */ "@/views/gebruiker/berichten/[messageId].vue"
							),
						props: (route) => {
							const resultParams = z
								.object({
									memberId: z.coerce.number(),
									messageId: z.coerce.number(),
								})
								.safeParse({
									memberId: route.params.id,
									...route.params,
								});

							if (!resultParams.success) {
								throw new Error("Invalid route params for 'Gebruiker bericht'", {
									cause: resultParams.error,
								});
							}

							return resultParams.data;
						},
						meta: { gebruikerView: true },
					},
				],
			},
		],
	},
	{
		name: "Gebruiker afspraak inplannen",
		path: "/gebruiker/:id/afspraak/inplannen",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/gebruiker/afspraak-inplannen" */ "@/views/GebruikerAfspraakInplannen.vue"
			),
		meta: { hideTopbar: true },
	},
	{
		name: "Gebruiker afspraak aanpassen",
		path: "/gebruiker/:id/afspraak/:afspraakId/aanpassen",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/gebruiker/afspraak-aanpassen" */ "@/views/GebruikerAfspraakAanpassen.vue"
			),
		meta: { hideTopbar: true },
	},
	{
		name: "Gebruiker belpoging",
		path: "/gebruiker/:id/belpoging",
		beforeEnter: requireAuth,
		component: async () =>
			await import(
				/* webpackChunkName: "views/gebruiker/belpoging" */ "@/views/GebruikerBelpoging.vue"
			),
		meta: { hideTopbar: true },
	},
	{
		name: "Leden aannemen",
		path: "/leden-aannemen",
		beforeEnter: requireAuth,
		component: async () =>
			await import(/* webpackChunkName: "views/leden-aannemen" */ "@/views/LedenAannemen.vue"),
		meta: { hideTopbar: false },
	},
	{
		name: "Not found",
		path: "/:catchAll(.*)*",
		alias: "/404",
		component: async () =>
			await import(/* webpackChunkName: "views/page-not-found" */ "@/views/NotFound.vue"),
		meta: { hideTopbar: true },
	},
];

export const router = new VueRouter({
	mode: "history",
	routes,
	scrollBehavior: (to, _from, savedPosition) => {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth",
			};
		} else if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0, behavior: "smooth" };
		}
	},
});

router.beforeEach((to, _, next) => {
	window.scrollTo(0, 0);

	useAppStore().$state.fullScreen = to.meta?.fullScreen ?? false;
	useAppStore().$state.showingTopbar = !(to.meta?.hideTopbar ?? false);

	return next();
});

export default router;
